import { Array, Optional, Record as RRecord, Static, String } from "runtypes";

export const CodeiumResponse = RRecord({
  state: RRecord({
    state: String,
    message: String,
  }),
  completionItems: Optional(
    Array(
      RRecord({
        completion: RRecord({ text: String, completionId: String }),
        range: RRecord({ startOffset: Optional(String), endOffset: String }),
      }),
    ),
  ),
});
export type CodeiumResponse = Static<typeof CodeiumResponse>;

export const CodeiumModelConfig = RRecord({});
export type CodeiumModelConfig = Static<typeof CodeiumModelConfig>;
